import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import './styles/index.sass'
import 'swiper/swiper-bundle.css'
import 'swiper/css/effect-fade'
import 'vue-advanced-cropper/dist/style.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'floating-vue/dist/style.css'
import 'viewerjs/dist/viewer.css'
// import 'vue3-pdf-app/dist/icons/main.css'

const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

const { $t } = app.config.globalProperties

const emailErrMessage = $t('This field must be a valid email')
const requiredErrMessage = $t('This field is required')
const confirmedErrMessage = $t('Passwords must match')
const minErrMessage = $t('Min length is')
const minNoSpacesErrMessage = $t('Min value is')
const maxErrMessage = $t('Max length is')
const alphaErrMessage = $t('Only alpha characters')
const arrayLengthMaxErrMessage = $t('Array max langth is')
const customPasswordErrMessage = $t('Password error')
const maskedPhoneErrorMessage = $t('Phone is not valid')
const noSpacesMaxErrorMessage = $t('The entered value must not exceed')
const noSpacesMinErrorMessage = $t('The entered value must not be less')
const codeErrMessage = $t('Invalid code length')
const repeatSymbolsErrorMessage = $t('The maximum number of repeated characters is')
const urlErrorMessage = $t('This field must be a valid URL')
const edrpouLegalErrorMessage = $t('ЕДРПОУ юр. особи має не валідний формат')

export {
  emailErrMessage,
  requiredErrMessage,
  confirmedErrMessage,
  minErrMessage,
  maxErrMessage,
  alphaErrMessage,
  arrayLengthMaxErrMessage,
  customPasswordErrMessage,
  minNoSpacesErrMessage,
  maskedPhoneErrorMessage,
  noSpacesMaxErrorMessage,
  noSpacesMinErrorMessage,
  codeErrMessage,
  repeatSymbolsErrorMessage,
  urlErrorMessage,
  edrpouLegalErrorMessage,
}
