import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    effect: "fade",
    modules: $setup.modules,
    autoHeight: true,
    allowTouchMove: false,
    loop: $setup.props.loop,
    spaceBetween: 12,
    speed: 500,
    slidesPerView: 1,
    navigation: { nextEl: '.js-speaker-fade-slider-next', prevEl: '.js-speaker-fade-slider-prev' },
    autoplay: { delay: 5000 }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["loop", "navigation"]))
}