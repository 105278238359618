import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-preview__badge"
}
const _hoisted_2 = { class: "video-preview__badge-text" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "video-preview__wrapper" }
const _hoisted_5 = { class: "video-preview__image" }
const _hoisted_6 = { class: "ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_4x2-custom" }
const _hoisted_7 = { class: "ds-aspect-ratio__body" }
const _hoisted_8 = {
  key: 0,
  class: "image-wrapper"
}
const _hoisted_9 = ["srcset"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "image-wrapper"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "video-preview__play" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_play = _resolveComponent("icon-play")!

  return (_openBlock(), _createElementBlock("div", {
    class: "video-preview",
    onClick: _withModifiers($setup.openVideoModal, ["prevent"])
  }, [
    ($setup.props.hint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString($setup.props.hint), 1),
          _createElementVNode("img", {
            class: "video-preview__badge-arrow",
            src: '/static/app/images/arrow-line.svg'
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            ($props.previewBase)
              ? (_openBlock(), _createElementBlock("picture", _hoisted_8, [
                  _createElementVNode("source", {
                    type: "image/webp",
                    srcset: $props.previewWebp
                  }, null, 8, _hoisted_9),
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $props.previewBase
                  }, null, 8, _hoisted_10)
                ]))
              : (_openBlock(), _createElementBlock("picture", _hoisted_11, [
                  _createElementVNode("img", {
                    class: "image-wrapper__item image-wrapper__item--fit",
                    src: $setup.youtubePreview
                  }, null, 8, _hoisted_12)
                ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_icon_play)
      ])
    ])
  ]))
}